import * as Yup from 'yup';

import { TFormFieldsFactory } from '../models/form-field.model';

export const passwordRecoveryForm: TFormFieldsFactory<'PasswordRecovery'> = ({ t }) => [
    {
        name: 'email',
        type: 'email',
        label: t.email.label,
        initialValue: '',
        placeholder: t.email.placeholder,
        schema: Yup.string().email(t.email.error.email).required(t.email.error.required),
    },
    {
        name: 'submit',
        type: 'submit',
        label: t.submit.label,
    },
];

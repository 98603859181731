import { useMemo } from 'react';

import { getUserTokenData } from '../utils/get-user-token-data';
import {
    useNewsletterMutation,
    usePasswordChangeMutation,
    usePasswordRecoveryMutation,
    usePasswordResetMutation,
} from '../redux/api/user';
import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import useTranslations from './use-translations';
import { getIsTokenExpired } from '../utils/get-is-token-expired';

export const useUser = () => {
    const t = useTranslations('RTKQueryApi');
    const tokenData = getUserTokenData();
    const { token, expireAt } = tokenData || {};
    const isTokenExpired = getIsTokenExpired(expireAt);
    const isLoggedIn = !!token && !isTokenExpired;

    const [recoverPassword, recoverPasswordMutation] = usePasswordRecoveryMutation();
    const [changePassword, changePasswordMutation] = usePasswordChangeMutation();
    const [resetPassword, resetPasswordMutation] = usePasswordResetMutation();
    const [toggleNewsletter, toggleNewsletterMutation] = useNewsletterMutation();

    const recoverPasswordErrors = useMemo(() => {
        return getRtkQueryErrors(recoverPasswordMutation.error, t.errors);
    }, [recoverPasswordMutation.error, t.errors]);
    const changePasswordErrors = useMemo(() => {
        return getRtkQueryErrors(changePasswordMutation.error, t.errors);
    }, [changePasswordMutation.error, t.errors]);
    const resetPasswordErrors = useMemo(() => {
        return getRtkQueryErrors(resetPasswordMutation.error, t.errors);
    }, [resetPasswordMutation.error, t.errors]);
    const toggleNewsletterErrors = useMemo(() => {
        return getRtkQueryErrors(toggleNewsletterMutation.error, t.errors);
    }, [t.errors, toggleNewsletterMutation.error]);

    return {
        isLoggedIn,
        recoverPassword: {
            fetch: recoverPassword,
            data: recoverPasswordMutation.data,
            isLoading: recoverPasswordMutation.isLoading,
            isSuccess: recoverPasswordMutation.isSuccess,
            isError: recoverPasswordMutation.isError,
            errors: recoverPasswordErrors,
        },
        changePassword: {
            fetch: changePassword,
            data: changePasswordMutation.data,
            isLoading: changePasswordMutation.isLoading,
            isSuccess: changePasswordMutation.isSuccess,
            isError: changePasswordMutation.isError,
            errors: changePasswordErrors,
        },
        resetPassword: {
            fetch: resetPassword,
            data: resetPasswordMutation.data,
            isLoading: resetPasswordMutation.isLoading,
            isSuccess: resetPasswordMutation.isSuccess,
            isError: resetPasswordMutation.isError,
            errors: resetPasswordErrors,
        },
        toggleNewsletter: {
            fetch: toggleNewsletter,
            data: toggleNewsletterMutation.data,
            isLoading: toggleNewsletterMutation.isLoading,
            isSuccess: toggleNewsletterMutation.isSuccess,
            isError: toggleNewsletterMutation.isError,
            errors: toggleNewsletterErrors,
        },
    };
};

import alterpressApi from './base';

import { IPasswordResetData, IPasswordChangeData } from '../../models/user.model';
import { INewsletterFormData } from '../../models/newsletter-form.model';

export const userApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['User'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            passwordRecovery: builder.mutation<void, void>({
                query: (data) => ({
                    method: 'POST',
                    url: 'password-reset-request',
                    body: data,
                }),
            }),
            passwordChange: builder.mutation<void, IPasswordChangeData>({
                query: (data) => ({
                    method: 'POST',
                    url: 'password-change',
                    body: data,
                }),
            }),
            passwordReset: builder.mutation<void, IPasswordResetData>({
                query: (data) => ({
                    method: 'POST',
                    url: 'password-reset',
                    body: data,
                }),
            }),
            newsletter: builder.mutation<
                void,
                INewsletterFormData & { action: 'subscribe' | 'unsubscribe' }
            >({
                query: ({ action, ...data }) => ({
                    method: 'POST',
                    url: `newsletter/${action}`,
                    body: data,
                }),
                invalidatesTags: ['User'],
            }),
        }),
    });

export const {
    usePasswordRecoveryMutation,
    usePasswordChangeMutation,
    usePasswordResetMutation,
    useNewsletterMutation,
} = userApi;
